import * as axios from 'axios';

export const API_BASE_URL = '/api/v1/wholesale';

export class WholesaleApiService {
    /**
     * @Devnote let's take a look at the below pattern...
     * @see https://medium.com/canariasjs/vue-api-calls-in-a-smart-way-8d521812c322
     * 
     */

    /**
     * Get Favorits for the User
     * 
     * @author LWK<lkrehnbrink@usdigitalpartners.com>
     */
    static getFavoritesForAuthenticatedUser() {
        return axios
            .get(`${API_BASE_URL}/favorites`)
            .then(
                ({
                    statusText,
                    status,
                    data
                }) => {
                    // console.log('Favorites', data);
                    return data;
                }
            );
    }

    /**
     * Get user information
     * 
     */
    static getAuthenticatedUserInformation() {
        return axios
            .get(`${API_BASE_URL}/user`)
            .then(
                ({
                    statusText,
                    status,
                    data
                }) => {
                    // console.log('User', data);
                    return data;
                }
            );
    }

    /**
     * Add Favorite
     * 
     * @author LWK<lkrehnbrink@usdigitalpartners.com>
     * @param {*} plantId 
     */
    static addFavoriteForAuthenticatedUser(plantId) {
        return axios
            .put(`${API_BASE_URL}/favorites/${plantId}`)
            .then(
                ({
                    statusText,
                    status,
                    data
                }) => {
                    // console.log('Favorite Added', plantId, data);
                    return data;
                }
            )
    }
    /**
     * Remove Favorite
     * 
     * @author LWK<lkrehnbrink@usdigitalpartners.com>
     * @param {number} plantId
     */
    static removeFavoriteForAuthenticatedUser(plantId) {
        return axios
            .delete(`${API_BASE_URL}/favorites/${plantId}`)
            .then(
                ({
                    statusText,
                    status,
                    data
                }) => {
                    // console.log('Favorite Removed', plantId, data);
                    return data;
                }
            )
    }

    /**
     * Get the Cart Object
     * 
     * @author LWK<lkrehnbrink@usdigitalpartners.com>
     * 
     * @return {Promise<Object{data{cart,taxRate}}>}
     */
    static getCartForAuthenticatedUser() {
        return axios
            .get(`${API_BASE_URL}/cart`)
            .then(
                ({
                    statusText,
                    status,
                    data
                }) => {
                    // console.log('Cart', data);
                    return data;
                }
            );
    }

    /**
     * Add item to Cart
     * 
     * @authorw LWK<lkrehnbrink@usdigitalpartners.com>
     */
    static addCartItemForAuthenticatedUser(productId, plantId, quantity) {
        return axios
            .post(
                `${API_BASE_URL}/cart/add`, 
                {
                    id: productId,
                    plant_id: plantId,
                    quantity: quantity
                }
            )
            .then(
                ({
                    statusText,
                    status,
                    data
                }) => {
                    // console.log('Cart', data);
                    return data;
                }
            );
    }

    /**
     * Update item in Cart
     * 
     * @author LWK<lkrehnbrink@usdigitalpartners.com>
     */
    static updateCartItemForAuthenticatedUser(productId, plantId, quantity) {
        return axios
            .post(
                `${API_BASE_URL}/cart/update`, 
                {
                    id: productId,
                    plant_id: plantId,
                    quantity: quantity
                }
            )
            .then(
                ({
                    statusText,
                    status,
                    data
                }) => {
                    // console.log('Cart', data);
                    return data;
                }
            );
    }

    /**
     * Remove item from Cart
     * 
     * @author LWK<lkrehnbrink@usdigitalpartners.com> 
     */
    static removeCartItemForAuthenticatedUser(productId) {
        return axios
            .post(
                `${API_BASE_URL}/cart/remove`, 
                {
                    id: productId
                }
            )
            .then(
                ({
                    statusText,
                    status,
                    data
                }) => {
                    // console.log('Cart', data);
                    return data;
                }
            )
    }

    /**
     * Get Products
     * 
     * This returns an object containing the collection of products
     * as well as the laravel eloquent pagination object.
     * 
     * @author LWK<lkrehnbrink@usdigitalpartners.com>
     * 
     * @see https://laravel.com/docs/5.8/pagination
     * 
     * @param {string} url 
     * @return {Promise<Object>}
     */
    static getProducts(url) {
        // console.log('URL', url);
        return axios
            .get(url)
            .then(
                ({
                    status,
                    statusText,
                    data: {
                        total,
                        per_page,
                        current_page,
                        last_page,
                        first_page_url,
                        last_page_url,
                        next_page_url,
                        prev_page_url,
                        path,
                        from,
                        to,
                        data
                    }
                }) => {
                    return {
                        data,
                        paginationInfo: { 
                            total, per_page, current_page, last_page, first_page_url, last_page_url, next_page_url, prev_page_url, path, from, to 
                        }
                    };
                }
            );
    }

    /**
     * Get Plant Details
     * 
     * @author LWK<lkrehnbrink@usdigitalpartners.com>
     */
    static getPlantDetails(plantId) {
        return axios
            .get(`${API_BASE_URL}/plants/${plantId}`)
            .then(
                ({
                    status,
                    statusText,
                    data
                }) => {
                    // console.log('Plant Detail', status, statusText, data);
                    return data;
                }
            );
    }
    
}