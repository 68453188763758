// Setup doesn't like this
// import { PROMO_CODE_TYPES } from '../data/promo-code-types';
/**
 * These need to match the PromoCodeTypes class from the API
 *
 * @see app\Models\PromoCodeTypes.php
 */
const PROMO_CODE_TYPES = {
	AMOUNT: 'amount',
	AMOUNT_OVER: 'amount_over',
	AMOUNT_PRODUCT: 'amount_product',
	PERCENT: 'percent',
	PERCENT_OVER: 'percent_over',
	PERCENT_PRODUCT: 'percent_product',
};
class cartHelper {
	constructor() {}

	init(config) {
		// console.log(config);
		var token = document.head.querySelector('meta[name="csrf-token"]');
		$.ajaxSetup({
			beforeSend: function(xhr) {
				xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
				xhr.setRequestHeader('X-CSRF-TOKEN', token.content);
			},
		});
		window.app.cart.bindCartToggles();

		// Vue.config.devtools = true;
		// Vue.config.productionTip = false;
		// Vue.filter('currency', function (value) {
		//     return '$' + parseFloat(value).toFixed(2);
		// });

		window.app.addToCartInstance = window.app.cart.bindAddToCartVue();
		if (config && config.hasOwnProperty('cartEl')) {
			Vue.prototype.braintree = braintree;
			window.app.cartInstance = window.app.cart.bindCartVue({
				el: config.cartEl,
				bindPayment: true,
				pickupText: config.pickupText,
				deliveryText: config.deliveryText,
				installationText: config.installationText,
			});
			// @DEVNOTE: this needs to be earlier in the load cycle
			// window.app.cartInstance.bindPayment();
		} else {
			window.app.cartInstance = window.app.cart.bindCartVue(config);
		}

		// Shopping List
		// window.app.shoppingList.init();

		// Backdrop Fix
		const backdrop = document.getElementById('cart-backdrop');
		if (backdrop) {
			backdrop.addEventListener('click', () =>
				window.app.cart.hideCart()
			);
		}
	}

	bindCartToggles(el) {
		$('[data-toggle-cart]').each(function() {
			var showShoppingList = $(this).data('shoppingList');
			// console.log(showShoppingList);
			$(this).click(function(e) {
				e.preventDefault();
				window.app.cart.showCart(showShoppingList);
			});
		});
	}

	bindAddToCartVue() {
		var addToCartData = window.addToCartData;
		var productImage = window.productImage;

		var dataLayer = window.dataLayer || [];

		if (!$('#add-to-cart')[0] || typeof addToCartData === 'undefined')
			return false;

		// add prop for binding
		addToCartData.forEach((item) => {
			item.showNotifyMessage = false;
		});

		return new Vue({
			delimiters: ['${', '}'],
			el: '#add-to-cart',
			data: {
				products: addToCartData,
				productImage: productImage,
				productId: null,
				ready: false,
				showAddedQuantity: false,
				addedToShoppingList: false,
			},
			computed: {
				totalItems: function() {
					return this.products.reduce((total, item) => {
						return total + item.quantity;
					}, 0);
				},
				disableAddToCart: function() {
					for (var item in this.products) {
						if (this.products[item].quantity > 0) {
							return false;
						}
					}
					return true;
				},
				anyProductAvailable: function() {
					for (var item in this.products) {
						if (this.products[item].available > 0) {
							return true;
						}
					}
					return false;
				},
			},
			methods: {
				initQuantities: function() {
					for (var item in this.products) {
						this.products[item].price = parseFloat(
							this.products[item].price
						);
					}
				},
				resetQuantities: function() {
					for (var item in this.products) {
						this.products[item].quantity = 0;
					}
				},
				decrementQuantity: function(index) {
					if (this.products[index].quantity == 0) return;
					this.showAddedQuantity = false;
					this.products[index].quantity--;
				},
				incrementQuantity: function(index) {
					if (
						this.products[index].quantity ==
						this.products[index].inventory
					)
						return;
					this.showAddedQuantity = false;
					this.products[index].quantity++;
				},
				addToCart: function() {
					// console.log('Will add ' + this.selectedProduct.name + ' to cart with quantity ' + this.selectedProduct.quantity);
					// @DEVNOTE: add event listener to reset quantity field on cart update
					var productCount = 0;
					var updatedProducts = [];
					var self = this;
					this.products.forEach((product) => {
						if (product.quantity > 0) {
							console.log('add product to cart', product);
							self.fireGTMaddToCart(product);
							setTimeout(function() {
								window.app.cartInstance.addItem(
									product.id,
									product.plant_id,
									product.quantity
								);
							}, productCount * 300);
							productCount++;
						}
					});
					var listener = window.addEventListener(
						'cartUpdated',
						function(e) {
							console.log('cartUpdated', e.detail.productId);
							updatedProducts.push({
								productId: e.detail.productId,
								plantId: e.detail.plantId,
								quantity: e.detail.quantity,
								status: 'success',
							});
							if (updatedProducts.length == productCount) {
								self.showCartStatus(updatedProducts);
							}
						}
					);
					var listener = window.addEventListener(
						'cartError',
						function(e) {
							console.log('cartError', e);
							updatedProducts.push({
								productId: e.detail.productId,
								plantId: e.detail.plantId,
								status: 'error',
							});
							if (updatedProducts.length == productCount) {
								self.showCartStatus(updatedProducts);
							}
						}
					);
				},
				showCartStatus: function(updatedProducts) {
					var html = '<div class="products-added">';
					var actualAdded = updatedProducts.reduce((total, item) => {
						return total + item.quantity;
					}, 0);
					html +=
						'<div class="total-added">' +
						actualAdded +
						' item' +
						(actualAdded > 1 ? 's' : '') +
						' Added to Cart</div>';

					updatedProducts.forEach((updatedProduct) => {
						var product = this.products.find(
							(product) => product.id == updatedProduct.productId
						);
						html += '<div class="product">';
						html +=
							'<div class="product-image" style="background-image: url(\'/assets/plants/' +
							this.productImage +
							'\');"></div>';
						html +=
							'<div class="product-info"><div class="product-name">' +
							product.name +
							'</div><div class="product-size">' +
							product.size +
							' x' +
							updatedProduct.quantity +
							'</div></div>';
						html += '</div>';
					});
					//html += '<div class="close-text">Click icon to review.</div>';
					window.app.cart.showStatus(html);
					this.showAddedQuantity = this.totalItems;
					this.resetQuantities();
				},
				notifyMe: function(product) {
					self = this;
					// console.log("Setting up notification");
					return $.ajax({
						url: '/cart/notify',
						method: 'POST',
						data: {
							product: product,
						},
						success: function(data) {
							// console.log(data);
							//self.notify = data.notify;
							//self.showNotifyMessage = true;
							// self.showNotify = false;
							//self.hideNotifyButton = false;
							self.products.find(
								(item) => item.id == product.id
							).showNotifyMessage = true;
						},
						error: function(error) {
							// console.log(error);
						},
					}).then(function() {
						// console.log('finished');
					});
				},
				fireGTMaddToCart: function(product) {
					//universal tag datalayer push
					var data = {
						event: 'addToCart',
						ecommerce: {
							currencyCode: 'USD',
							add: {
								products: [
									{
										name: product.name,
										id: product.id,
										price: product.price.toString(),
										category: product.category,
										variant: product.size,
										quantity: parseInt(product.quantity),
									},
								],
							},
						},
					};
					dataLayer.push(data);

					//ga4 tag datalayer push
					var g4data = {
						event: 'add_to_cart',
						ecommerce: {
							currencyCode: 'USD',
							add: {
								products: [
									{
										name: product.name,
										id: product.id,
										price: product.price.toString(),
										category: product.category,
										variant: product.size,
										quantity: parseInt(product.quantity),
									},
								],
							},
						},
					};
					dataLayer.push(g4data);
				},
				// Shopping List Functionality
				addToShoppingList: function() {
					var self = this;
					window.app.cartInstance.addItemToShoppingList(
						self.productId
					);
					var listener = window.addEventListener(
						'shoppingListUpdated',
						function(e) {
							self.addedToShoppingList = true;
							// console.log('Event response', e);
						}
					);
				},
			},
			mounted: function() {
				this.initQuantities();
				this.selectedProduct = this.products[0];
				this.productId = this.products[0].plant_id;
				// console.log('Add to cart mounted', this.products);
			},
		});
	}

	showCart(showShoppingList) {
		var cart = document.getElementById('cart-slideout'),
			backdrop = document.getElementById('cart-backdrop');

		var activePane = showShoppingList === true ? 'shoppingList' : 'cart';
		cart.classList.add('in', 'animated', 'slideInRight');
		backdrop.classList.add('in', 'animated', 'fadeIn');
		var timeOut = setTimeout(function() {
			cart.classList.remove('animated', 'slideInRight');
			backdrop.classList.remove('animated', 'fadeIn');
			window.app.cartInstance.$data.shown = true;
			window.app.cartInstance.$data.activePane = activePane;
			clearTimeout(timeOut);
		}, 300);
	}

	hideCart(e) {
		var cart = document.getElementById('cart-slideout'),
			backdrop = document.getElementById('cart-backdrop');

		cart.classList.add('animated', 'slideOutRight');
		backdrop.classList.add('animated', 'fadeOut');
		var timeOut = setTimeout(function() {
			cart.classList.remove('in', 'animated', 'slideOutRight');
			backdrop.classList.remove('in', 'animated', 'fadeOut');
			window.app.cartInstance.$data.shown = false;
			clearTimeout(timeOut);
		}, 300);
	}

	showStatus(message, shoppingList) {
		var shoppingListText =
			typeof shoppingList !== 'undefined' && shoppingList == true;
		var statusEl = document.getElementById(
				'cart-status'
				//shoppingListText ? 'shopping-list-status' : 'cart-status'
			),
			statusText = document.getElementById(
				'cart-status-text'
				//shoppingListText
				//	? 'shopping-list-status-text'
				//	: 'cart-status-text'
			);
		statusText.innerHTML = message;
		statusEl.classList.add('in', 'animated', 'fadeIn');
		var timeOut = setTimeout(function() {
			statusEl.classList.remove('animated', 'fadeIn');
			statusEl.classList.add('animated', 'fadeOut');
			var timeEnd = setTimeout(function() {
				statusEl.classList.remove('in', 'animated', 'fadeOut');
				clearTimeout(timeEnd);
			}, 1000);
			clearTimeout(timeOut);
		}, 3500);
	}

	bindCartVue(config) {
		var upsellItems = window.upsellItems;
		var oldFormValues = window.oldFormValues;
		var user_zipcode = window.user_zipcode;
		var PickupText = config.pickupText
			? config.pickupText
			: 'I understand that my purchase is for in store pick up only and it may take up to 4 days for my order to be processed and ready for pick up.';
		var DeliveryText = config.deliveryText
			? config.deliveryText
			: 'I understand that my purchase may take two to three weeks to be delivered. Natorp’s will contact me to schedule a delivery date within the next 48 hours.';
		var InstallationText = config.installationText
			? config.installationText
			: 'I understand that the lead time for installation is 4 weeks. Natorp’s will contact me to schedule an installation date within the next 48 hours';
		if (!$(config && config.el ? config.el : '#cart-slideout')[0]) {
			// Prevent Vue initialization if we don't have the element
			return false;
		}

		return new Vue({
			delimiters: ['${', '}'],
			el: config && config.el ? config.el : '#cart-slideout',
			data: {
				shown: false,
				ready: false,
				shoppingListAvailable: false,
				updating: false,
				submitting: false,
				cart: [],
				upsellItems: upsellItems,
				zipCode:
					oldFormValues && oldFormValues.zipcode
						? oldFormValues.zipcode
						: user_zipcode,

				// Fullfillment settings
				isInStorePickup:
					oldFormValues && oldFormValues.isInStorePickup
						? oldFormValues.isInStorePickup == 'true'
						: true,
				isDelivery:
					oldFormValues && oldFormValues.isDelivery
						? oldFormValues.isDelivery == 'true'
						: false,
				isInstallation:
					oldFormValues && oldFormValues.isInstallation
						? oldFormValues.isInstallation == 'true'
						: false,
				shippingNotAvailable: false,
				min_delivery_amount: window._NATORPS.general.DeliveryMinimum,
				min_install_fee: window._NATORPS.general.minimum_install_fee,
				multiple_install_quantity:
					window._NATORPS.general.multiple_install_quantity,
				multiple_install_discount:
					window._NATORPS.general.multiple_install_discount,
				min_order_amount: (
					window._NATORPS.general.minimum_order_price || 0
				).toFixed(2),
				shippingRate: 0,
				shippingTaxRate: null,
				displayFullfillmentOptions:
					this.isDelivery || this.isInstallation || false,
				// showShippingForm:
				// 	oldFormValues && oldFormValues.include_shipping
				// 		? oldFormValues.include_shipping
				// 		: false,
				// includeShipping:
				// 	oldFormValues && oldFormValues.include_shipping
				// 		? oldFormValues.include_shipping
				// 		: false,

				variants: [],
				fertilizerAmount: null,
				conditionerAmount: null,
				selectedProduct: [],
				shoppingList: [],
				activePane: 'cart',
				taxRate: null,
				confirmRemove: false,
				confirmShoppingListRemove: false,
				confirmEmptyShoppingList: false,
				confirmEmailShoppingList: false,
				shoppingListState: 'action',
				orderNotes:
					oldFormValues && oldFormValues.order_notes
						? oldFormValues.order_notes
						: '',
				loading: true,
				events: {
					cartUpdateEvent: new window.CustomEvent('cartUpdated'),
					cartErrorEvent: new window.CustomEvent('cartError'),
					shoppingListUpdateEvent: new window.CustomEvent(
						'shoppingListUpdated'
					),
				},
				hasConfirmedDisclaimer: false,
				disclaimerText: PickupText,
				promoCode: null,
				giftCards: [],
			},
			computed: {
				isShippingAllowed: function() {
					var total = this.cartSubtotalWithoutShipping;
					// handle delivery status check
					if (total < this.min_delivery_amount) {
						this.displayFullfillmentOptions = false;
						this.enableInStore();
						return false;
					} else {
						return true;
					}
				},
				cartTotalIsAboveMinimumOrderAmount: function() {
					return parseFloat(this.cartTotal) >= this.min_order_amount;
				},
				disableAddToCart: function() {
					for (
						var i = 0, len = this.selectedProduct.length;
						i < len;
						i++
					) {
						if (
							this.selectedProduct[i] &&
							this.selectedProduct[i].quantity > 0
						) {
							return null;
						}
					}
					return true;
					// return null;
				},
				cartEl: function() {
					return document.getElementById(
						config && config.el
							? config.el.replace('#', '')
							: 'cart-slideout'
					);
				},
				cartCount: function() {
					var count = 0;
					for (var item in this.cart) {
						count += parseInt(this.cart[item].quantity);
					}
					return count;
				},
				// cartHasOutofStockItem: function() {
				// 	for (let item in this.cart) {
				// 		if (
				// 			this.cart[item].quantity > this.cart[item].inventory
				// 		)
				// 			return true;
				// 	}
				// 	return false;
				// },
				shoppingListCount: function() {
					var count = 0;
					for (var item in this.shoppingList) {
						count++;
					}
					return count;
				},
				backdropEl: function() {
					return document.getElementById('cart-backdrop');
				},
				cartClass: function() {
					//DEVNOTE DEBUG CLASS BINDING
					var str = '';
					if (this.cart.length == 0) str += ' empty';
					// if (this.updating) str += ' updating';
					return str;
				},
				promoCodeDescription: function() {
					if (this.promoCode) {
						// PromoCode discount description
						switch (this.promoCode.type) {
							case PROMO_CODE_TYPES.AMOUNT:
							case PROMO_CODE_TYPES.AMOUNT_OVER:
							case PROMO_CODE_TYPES.AMOUNT_PRODUCT:
								// FIXED AMOUNT
								return `$${this.promoCode.amount} off your before-tax order total`;
							case PROMO_CODE_TYPES.PERCENT:
							case PROMO_CODE_TYPES.PERCENT_OVER:
								// FIXED PERCENT
								// Percent values are integers between 1 and 100
								return `${this.promoCode.percent}% off your before-tax order total`;
							default:
								// FIXED PERCENT SINGLE PRODUCT
								return `${this.promoCode.percent}% off ${this.promoCode.required_product.ProductName2} (${this.promoCode.required_product.ProductSize})`;
						}
					}
					return '';
				},
				// DOLLAR AMOUNT of DISCOUNT
				promoCodeDiscountToApply: function() {
					if (this.promoCode) {
						/**
						 * Calculate PromoCode discount
						 *
						 * @Devnote percents, order minimums, and required products
						 * can be inferred from the promoCode data. The only additional
						 * DISCOUNT_TYPE that needs calculation is a PERCENT DISCOUNT on
						 * a CERTAIN PRODUCT in the cart.
						 *
						 * @author LWK
						 */
						let discount;
						switch (this.promoCode.type) {
							case PROMO_CODE_TYPES.AMOUNT:
							case PROMO_CODE_TYPES.AMOUNT_OVER:
							case PROMO_CODE_TYPES.AMOUNT_PRODUCT:
								// FIXED AMOUNT
								return parseFloat(
									this.promoCode.amount
								).toFixed(2);
							case PROMO_CODE_TYPES.PERCENT:
							case PROMO_CODE_TYPES.PERCENT_OVER:
								// FIXED PERCENT
								// Percent values are integers between 1 and 100
								discount = (
									parseFloat(
										this.cartSubtotalWithoutShipping
									) *
									(parseFloat(this.promoCode.percent) / 100)
								).toFixed(2);
								return parseFloat(discount).toFixed(2);
							case PROMO_CODE_TYPES.PERCENT_PRODUCT:
								// FIXED PERCENT OFF SINGLE PRODUCT IN CART
								const product = this.cart.find(
									(item) =>
										item.id ===
										this.promoCode.required_product_id
								);
								discount = (
									parseInt(product.quantity) *
									parseFloat(product.price) *
									(parseFloat(this.promoCode.percent) / 100)
								).toFixed(2);
								return parseFloat(discount).toFixed(2);
							default:
								return 0;
						}
					}
					return 0;
				},
				cartSubtotalWithoutShipping: function() {
					var total = 0,
						upsell_fertilizer_total = 0;
					for (var item in this.cart) {
						if (this.shouldShowProduct(this.cart[item])) {
							total +=
								this.cart[item].price *
								this.cart[item].quantity;
						}
					}
					this.calculateUpsell();

					return total.toFixed(2);
				},
				// COST of combined total items in cart AND shipping, before TAX
				cartSubtotal: function() {
					var total = 0,
						upsell_fertilizer_total = 0;
					for (var item in this.cart) {
						if (this.shouldShowProduct(this.cart[item])) {
							total +=
								this.cart[item].price *
								this.cart[item].quantity;
						}
					}
					total += parseFloat(this.cartShippingHandling);
					this.calculateUpsell();
					return total.toFixed(2);
				},
				// TAX calculated on ORDER TOTAL + SHIPPING - DISCOUNT
				cartTax: function() {
					var subtotal = parseFloat(
						this.cartTotalAfterPromoCodeDiscount
					);
					// var shipping = parseFloat(this.cartShippingHandling);
					// if (
					// 	this.includeShipping &&
					// 	this.cartSubtotal >= this.min_delivery_amount
					// ) {
					// 	subtotal += shipping;
					// }
					if (this.shippingTaxRate) {
						tax = subtotal * parseFloat(this.shippingTaxRate);
					} else {
						tax = subtotal * this.taxRate;
					}
					return tax.toFixed(2);
				},
				cartShippingHandling: function() {
					var shipping = 0;
					if (this.isInstallation) {
						var count = 0;
						const multipleItemDiscount =
							this.cart.length >=
								this.multiple_install_quantity ||
							this.cart.some(
								(i) =>
									i.quantity >= this.multiple_install_quantity
							);
						console.log(multipleItemDiscount, this.cart);
						for (var item in this.cart) {
							if (this.shouldShowProduct(this.cart[item])) {
								count += this.cart[item].quantity;
								let itemInstallFee =
									this.cart[item].install_price *
									this.cart[item].quantity;
								if (multipleItemDiscount) {
									itemInstallFee =
										itemInstallFee *
										((100 -
											this.multiple_install_discount) /
											100);
								}
								shipping += itemInstallFee;
							}
						}
						// if(count >= 2) {
						// 	// apply 20% discount for more than one item
						// 	shipping =
						// 		shipping *
						// 		((100 - this.multiple_install_discount) / 100);
						// }
						if (shipping <= this.min_install_fee) {
							shipping = this.min_install_fee;
						}
					} else if (this.isDelivery) {
						shipping = this.shippingRate * 1;
					}
					return parseFloat(shipping).toFixed(2);
				},
				cartTotalAfterPromoCodeDiscount: function() {
					if (this.promoCode) {
						return (
							this.cartSubtotal -
							parseFloat(this.promoCodeDiscountToApply)
						).toFixed(2);
					}
					return this.cartSubtotal;
				},
				cartTotalPreGiftCards: function() {
					var total = 0;
					total =
						parseFloat(this.cartTotalAfterPromoCodeDiscount) +
						parseFloat(this.cartTax);
					// if (
					// 	(this.isDelivery || this.isInstallation) &&
					// 	total >= this.min_delivery_amount
					// ) {
					// 	total += parseFloat(this.cartShippingHandling);
					// }
					return total.toFixed(2);
				},
				cartTotal: function() {
					var total = parseFloat(this.cartTotalPreGiftCards);
					for (var card in this.giftCards) {
						total -= this.giftCards[card].appliedValue;
					}
					return total.toFixed(2);
				},
				showFertilizer: function() {
					if (this.fertilizerAmount > 0) {
						return true;
					}
					return false;
				},
				showConditioner: function() {
					if (this.conditionerAmount > 0) {
						return true;
					}
					return false;
				},
			},
			methods: {
				handleSelectedPromoCode: function(promoCode) {
					this.promoCode = promoCode;
				},
				handleGiftCards: function(giftCards) {
					this.giftCards = giftCards;
				},
				zipcodeChanged: function(event) {
					//this.zipCode = event.target.value;
					this.checkZipcode();
				},
				plantInstallationChanged: function() {
					if (document.getElementById('plantInstallation').checked) {
						this.plantInstallationChecked = true;
					} else {
						this.plantInstallationChecked = false;
					}
				},
				calculateUpsell: function() {
					var upsell_fertilizer_total = 0,
						upsell_conditioner_total = 0;
					for (var item in this.cart) {
						upsell_fertilizer_total +=
							this.cart[item].start_fert_qty *
							this.cart[item].quantity;
						upsell_conditioner_total +=
							this.cart[item].soil_cond_qty *
							this.cart[item].quantity;
					}
					this.fertilizerAmount = upsell_fertilizer_total.toFixed(1);
					this.conditionerAmount = upsell_conditioner_total.toFixed(
						1
					);
				},
				shouldShowProduct: function(item) {
					return (
						!this.isInstallation ||
						(this.isInstallation &&
							!this.upsellItems.some((x) => x[0].id == item.id))
					);
				},
				enableInStore: function(event) {
					console.log(event);
					this.isInStorePickup = true;
					this.isDelivery = false;
					this.isInstallation = false;
					this.disclaimerText = PickupText;
					this.hasConfirmedDisclaimer = false;
					//this.showShippingForm = false;
					// this.displayAddShippingButton = false;
					// this.plantInstallationChecked = false;
					// this.shippingRate = 0;
					// this.shippingTaxRate = 0;
					// this.includeShipping = false;
					// document.getElementById(
					// 	'plantInstallation'
					// ).checked = false;
				},
				enableDelivery: function(event) {
					this.isInStorePickup = false;
					this.isDelivery = true;
					this.isInstallation = false;
					this.disclaimerText = DeliveryText;
					this.hasConfirmedDisclaimer = false;
					// this.showShippingForm = true;
					// this.includeShipping = true;
				},
				enableInstallation: function(event) {
					this.isInStorePickup = false;
					this.isDelivery = false;
					this.isInstallation = true;
					this.disclaimerText = InstallationText;
					this.hasConfirmedDisclaimer = false;
					//this.showShippingForm = true;
					//this.includeShipping = true;
				},
				checkZipcode: function() {
					var self = this;
					if (this.zipCode) {
						// console.log("Setting up notification");
						return $.ajax({
							url: '/cart/check-zipcode',
							method: 'POST',
							data: {
								zipcode: this.zipCode,
							},
							success: function(data) {
								if (data.available === false) {
									self.shippingNotAvailable = true;
									self.shippingRate = 0;
									self.shippingTaxRate = null;

									self.displayFullfillmentOptions = false;
									self.enableInStore();
									// self.isInStorePickup = true;
									// self.isDelivery = false;
									// self.isInstallation = false;

									//self.includeShipping = false;
									//self.showShippingForm = false;
								} else {
									self.shippingRate = data.DeliveryFee;
									self.shippingTaxRate = data.TaxRate;
									self.shippingNotAvailable = false;
									self.displayFullfillmentOptions = true;
									self.zip;
								}
							},
							error: function(error) {
								// console.log(error);
							},
						}).then(function() {
							// console.log('finished');
						});
					}
				},
				decrementUpsellQuantity: function(upsellIndex, index) {
					if (this.upsellItems[upsellIndex][index].quantity == 0)
						return;
					this.showAddedQuantity = false;
					this.upsellItems[upsellIndex][index].quantity--;
				},
				incrementUpsellQuantity: function(upsellIndex, index) {
					if (
						this.upsellItems[upsellIndex][index].quantity ==
						this.upsellItems[upsellIndex][index].inventory
					)
						return;
					this.showAddedQuantity = false;
					this.upsellItems[upsellIndex][index].quantity++;
				},
				addUpsellToCart: function() {
					var productCount = 0;
					var self = this;
					this.upsellItems.forEach((upsellItem) => {
						upsellItem.forEach((product) => {
							if (product.quantity > 0) {
								console.log('add product to cart', product);
								self.fireGTMaddToCart(product);
								setTimeout(function() {
									window.app.cartInstance.addItem(
										product.id,
										product.plant_id,
										product.quantity
									);
								}, productCount * 300);
								productCount++;
							}
						});
					});

					// var self = this;
					// var product = null;
					// for (
					// 	var i = 0, len = this.selectedProduct.length;
					// 	i < len;
					// 	i++
					// ) {
					// 	(function(i) {
					// 		// @Without the delay the items would not be added consistently.
					// 		setTimeout(function() {
					// 			if (
					// 				self.selectedProduct[i] &&
					// 				self.selectedProduct[i].quantity > 0
					// 			) {
					// 				// console.log(self.selectedProduct[i]);
					// 				// console.log('Will add ' + self.selectedProduct[i].name + ' to cart with quantity ' + self.selectedProduct[i].quantity);
					// 				// @DEVNOTE: add event listener to reset quantity field on cart update
					// 				product = self.selectedProduct[i];
					// 				self.fireGTMaddToCart(product);
					// 				window.app.cartInstance.addItem(
					// 					product.id,
					// 					product.plant_id,
					// 					product.quantity
					// 				);
					// 			}
					// 		}, 100 * i);
					// 	})(i);
					// }

					setTimeout(function() {
						// @After a bit of time we reset the quantities
						self.resetQuantities();
					}, 800);
				},
				fireGTMaddToCart: function(product) {
					//universal tag datalayer push
					var data = {
						event: 'addToCart',
						ecommerce: {
							currencyCode: 'USD',
							add: {
								products: [
									{
										name: product.name,
										id: product.id,
										price: product.price.toString(),
										category: product.category,
										variant: product.size,
										quantity: parseInt(product.quantity),
									},
								],
							},
						},
					};
					// console.log('GTM add to cart', data);
					dataLayer.push(data);

					//universal tag datalayer push
					var g4data = {
						event: 'add_to_cart',
						ecommerce: {
							currencyCode: 'USD',
							add: {
								products: [
									{
										name: product.name,
										id: product.id,
										price: product.price.toString(),
										category: product.category,
										variant: product.size,
										quantity: parseInt(product.quantity),
									},
								],
							},
						},
					};
					dataLayer.push(g4data);
				},
				resetQuantities: function() {
					for (
						var i = 0, len = this.selectedProduct.length;
						i < len;
						i++
					) {
						this.selectedProduct[i].quantity = 0;
					}
					for (var item in this.products) {
						this.products[item].quantity = 0;
					}
				},
				activeUpsellProductTotal: function(index) {
					return (
						this.selectedProduct[index].price *
						this.selectedProduct[index].quantity
					);
				},
				showCart: function() {
					return window.app.cart.showCart();
				},
				hideCart: function() {
					return window.app.cart.hideCart();
				},
				decrementQuantity: function(index) {
					if (this.cart[index].quantity == 1) return;

					var self = this,
						quantity = parseInt(this.cart[index].quantity) - 1;

					this.updateItem(this.cart[index].id, quantity);
				},
				incrementQuantity: function(index) {
					if (this.cart[index].quantity == this.cart[index].inventory)
						return;
					var self = this,
						quantity = parseInt(this.cart[index].quantity) + 1;

					this.updateItem(this.cart[index].id, quantity);
				},
				hasItem: function(id, quantity) {
					for (var item in this.cart) {
						if (this.cart[item].id == id) {
							// console.log('Cart has item ' + id +'. Will update quantity.');
							return this.cart[item];
						}
					}
					return false;
				},
				updateItem: function(id, quantity) {
					var self = this;
					self.updating = true;
					return $.ajax({
						url: '/cart/update',
						method: 'POST',
						data: {
							id: id,
							quantity: quantity,
						},
						success: function(data) {
							// console.log(data);
							self.cart = data.cart;
						},
						error: function(error) {
							// console.log(error);
						},
					}).then(function() {
						self.finishUpdate();
					});
				},
				addItem: function(productId, plantId, quantity) {
					// console.log('Will add to cart', 'Item ID' + productId, 'Plant ID' + plantId, 'Quantity ' + quantity);

					var self = this,
						message = '';

					/// Check if item exists, increment quantity if possible
					var item = this.hasItem(productId);

					if (item !== false) {
						var addQuantity;
						// addQuantity =
						// 	parseInt(item.quantity) + parseInt(quantity);
						if (
							item.quantity + parseInt(quantity) <=
							item.inventory
						) {
							addQuantity =
								parseInt(item.quantity) + parseInt(quantity);
						} else {
							addQuantity = parseInt(item.inventory);
						}
						this.updateItem(productId, addQuantity).then(
							function() {
								window.dispatchEvent(
									new window.CustomEvent('cartUpdated', {
										detail: {
											productId: productId,
											plantId: plantId,
											quantity: quantity,
										},
									})
								);
								// CHANGE: Handling this in the add to cart module
								// window.app.cart.showStatus(
								// 	'Cart item updated.'
								// );
							}
						);
					} else {
						/// Cart Addition logic to implement
						// console.log({
						//     id: productId,
						//     plant_id: plantId,
						//     quantity: quantity
						// });

						self.updating = true;
						$.ajax({
							method: 'POST',
							url: '/cart/add',
							data: {
								id: productId,
								plant_id: plantId,
								quantity: quantity,
							},
							success: function(data) {
								//console.log(data.cart);
								self.cart = data.cart;
								message = 'Item added to cart.';
								// el.innerHTML = 'Added to cart <i class="fa fa-check"></i>';
							},
							error: function(error) {
								console.error(error);
								//message = 'Error adding item to cart.';
								window.dispatchEvent(
									new window.CustomEvent('cartError', {
										detail: {
											productId: productId,
											plantId: plantId,
										},
									})
								);
							},
						}).then(function() {
							self.finishUpdate();
							// @DEVNOTE: convert to vue event
							window.dispatchEvent(
								new window.CustomEvent('cartUpdated', {
									detail: {
										productId: productId,
										plantId: plantId,
										quantity: quantity,
									},
								})
							);
							// CHANGE: Handling this in the add to cart module
							//window.app.cart.showStatus(message);
						});
					}
				},
				removeItem: function(index) {
					this.updating = true;
					this.confirmRemove = false;
					var self = this,
						id = this.cart[index].id;
					self.fireGTMremoveFromCart(this.cart[index]);
					$.ajax({
						url: '/cart/remove',
						method: 'POST',
						data: {
							id: id,
						},
						success: function(data) {
							// console.log(data);
							self.cart = data.cart;
						},
						error: function(error) {
							// console.log(error);
						},
					}).then(function() {
						self.finishUpdate();
					});
				},
				fireGTMremoveFromCart: function(product) {
					//universal tag datalayer push
					var data = {
						event: 'removeFromCart',
						ecommerce: {
							currencyCode: 'USD',
							add: {
								products: [
									{
										name: product.title,
										id: product.id,
										price: product.price.toString(),
										category: product.category,
										variant: product.size,
										quantity: parseInt(product.quantity),
									},
								],
							},
						},
					};
					// console.log('GTM remove from cart', data);
					dataLayer.push(data);
					//universal tag datalayer push
					var g4data = {
						event: 'remove_from_cart',
						ecommerce: {
							currencyCode: 'USD',
							add: {
								products: [
									{
										name: product.title,
										id: product.id,
										price: product.price.toString(),
										category: product.category,
										variant: product.size,
										quantity: parseInt(product.quantity),
									},
								],
							},
						},
					};
					dataLayer.push(g4data);
				},
				fireGTMcheckoutAction: function(step) {
					var productArray = [];
					for (var i = 0; i < this.cart.length; i++) {
						var product = this.cart[i];
						productArray.push({
							name: product.title,
							id: product.id,
							price: product.price.toString(),
							category: product.category,
							variant: product.size,
							quantity: parseInt(product.quantity),
						});
					}
					//universal tag datalayer push
					var data = {
						event: 'productCheckout',
						ecommerce: {
							detail: {
								actionField: { step: step },
								products: productArray,
							},
						},
					};
					// console.log('GTM checkout step 2', data);
					dataLayer.push(data);

					//ga4 tag datalayer push
					var g4data = {
						event: 'begin_checkout',
						ecommerce: {
							detail: {
								actionField: { step: step },
								products: productArray,
							},
						},
					};
					// console.log('GTM checkout step 2', data);
					dataLayer.push(g4data);
				},
				finishUpdate: function() {
					var self = this,
						timeOut = setTimeout(function() {
							self.updating = false;
							clearTimeout(timeOut);
						}, 500);
					self.broadcastCartCount();
					self.broadcastShoppingListCount();
				},
				bindPayment: function() {
					/**
					 * Braintree Integration Details
					 *
					 * @see https://developers.braintreepayments.com/guides/drop-in/setup-and-integration/javascript/v3
					 */
					var client_token = document.head.querySelector(
							'meta[name="bt_client_token"]'
						).content,
						dropin_config = {
							authorization: client_token,
							selector: '#bt-dropin',
							paypal: {
								flow: 'vault', // Clarify difference between 'vault' and 'checkout' workflows
							},
							venmo: {
								allowNewBrowserTab: false, // review this functionality...
							},
						},
						_this = this;

					self.braintree.dropin.create(dropin_config, function(
						createErr,
						instance
					) {
						if (createErr) {
							// console.log('Create Error', createErr);
							return;
						}

						var paymentForm = $('#payment-form'),
							orderButton = $('#order-submit'),
							brainTree = $('.bt-dropin-wrapper');

						orderButton.on('click', function(event) {
							_this.submitting = true;
							event.preventDefault();
							// if order has value needed to be charged
							if (_this.cartTotal > 0) {
								instance.requestPaymentMethod(function(
									err,
									payload
								) {
									if (err) {
										// console.log('Request Payment Method Error', err);
										_this.submitting = false;
										return;
									}
									document.querySelector('#nonce').value =
										payload.nonce;

									brainTree.hide();
									orderButton.attr('disabled', 'disabled');

									_this.fireGTMcheckoutAction(2);

									paymentForm.submit();
								});
							} else {
								// order has been paid in full with gift cards
								paymentForm.submit();
							}
						});
					});
				},
				// Shopping List
				hasShoppingListItem: function(productId) {
					for (var item in this.shoppingList) {
						if (this.shoppingList[item].id == productId) {
							// console.log('Shopping List has item ' + productId);
							return true;
						}
					}
					return false;
				},
				addItemToShoppingList: function(productId) {
					// console.log('Will add product to shopping list. ID:', productId);
					var self = this,
						message = '';
					if (this.hasShoppingListItem(productId)) {
						window.app.cart.showStatus(
							'<div class="shopping-list-item-added">Item already in Shopping List</div>',
							true
						);
						window.dispatchEvent(
							self.events.shoppingListUpdateEvent
						);
					} else {
						$.ajax({
							method: 'POST',
							url: '/shoppingList/add',
							data: {
								id: productId,
							},
							success: function(data) {
								// console.log(data);
								self.shoppingList = data.shoppingList;
								message =
									'<div class="shopping-list-item-added">Item added to Wish List</div>';
							},
							error: function(error) {
								// console.log(error);
								message =
									'<div class="shopping-list-item-added">Error adding item to Wish List</div>';
							},
						}).then(function() {
							window.dispatchEvent(
								self.events.shoppingListUpdateEvent
							);
							window.app.cart.showStatus(message, true);
							self.finishUpdate();
						});
					}
				},
				removeShoppingListItem: function(index) {
					// console.log('Will remove shopping list item with id', this.shoppingList[index].id);
					this.updating = true;
					this.confirmShoppingListRemove = false;
					var self = this,
						id = this.shoppingList[index].id;
					$.ajax({
						url: '/shoppingList/remove',
						method: 'POST',
						data: {
							id: id,
						},
						success: function(data) {
							// console.log(data);
							self.shoppingList = data.shoppingList;
						},
						error: function(error) {
							// console.log(error);
						},
					}).then(function() {
						self.finishUpdate();
					});
				},
				emptyShoppingList: function() {
					// console.log('Will empty shopping list');
					this.updating = true;
					this.confirmEmptyShoppingList = false;
					var self = this;
					$.ajax({
						method: 'POST',
						url: '/shoppingList/empty',
						success: function(data) {
							// console.log(data);
							self.shoppingList = data.shoppingList;
						},
						error: function(error) {
							// console.log(error);
						},
					}).then(function() {
						self.finishUpdate();
					});
				},
				emailShoppingList: function() {
					// console.log('Will email shopping list');
					this.updating = true;
					var self = this;
					$.ajax({
						method: 'POST',
						url: '/shoppingList/email',
						success: function(data) {
							// console.log('Success:', data);
						},
						error: function(error) {
							// console.log('Error:', error);
						},
					}).then(function() {
						self.finishUpdate();
						self.shoppingListState = 'notify';
						setTimeout(function() {
							self.shoppingListState = 'action';
							self.confirmEmailShoppingList = false;
						}, 2000);
					});
				},
				broadcastCartCount: function() {
					var count = this.cartCount;
					$('[data-bind="cart-count"]').each(function() {
						// console.log('Broadcast:cartCount');
						$(this).html(
							count > 0
								? '<span class="cart-count">' +
										count +
										'</span>'
								: ''
						);
					});
				},
				broadcastShoppingListCount: function() {
					var count = this.shoppingListCount;
					$('[data-bind="shopping-list-count"]').each(function() {
						// console.log('Broadcast:shoppingListCount');
						$(this).html(
							count > 0
								? '<span class="cart-count">' +
										count +
										'</span>'
								: ''
						);
					});
				},
			},
			created: function() {
				//console.log('Vue created', this);
				var self = this;

				this.displayFullfillmentOptions =
					this.isDelivery || this.isInstallation;
				if (this.displayFullfillmentOptions) {
					this.checkZipcode();
				}

				if (config.hasOwnProperty('authorized')) {
					if (!config.authorized) {
						// console.log('Unauthorized. Cart will not initialize');
						self.ready = true;
						return;
					} else {
						// console.log('Authorized. Cart will initialize');
					}
				}
				$.ajax({
					method: 'GET',
					url: '/cart',
					success: function(data) {
						self.cart = data.cart;
						self.taxRate = data.taxRate;
						self.ready = true;
						if (
							config &&
							config.hasOwnProperty('bindPayment') &&
							config.bindPayment
						) {
							self.bindPayment();
						}
						console.log(data);
						// self.loading = false;
					},
					error: function(err) {
						// console.log(error);
					},
				}).then(function() {
					// console.log('Cart retrieved, hide loading indicator.');
					self.loading = false;
					self.broadcastCartCount();
				});
				$.ajax({
					method: 'GET',
					url: '/shoppingList',
					success: function(data) {
						self.shoppingList = data.shoppingList;
						self.shoppingListAvailable = true;
						// console.log(data);
					},
					error: function(err) {
						// console.log(error);
					},
				}).then(function() {
					self.broadcastShoppingListCount();
				});
			},
			mounted: function() {
				if (this.upsellItems) {
					for (
						var i = 0, len = this.upsellItems.length;
						i < len;
						i++
					) {
						this.selectedProduct[i] = this.upsellItems[i][0];
					}
				}
				// this.checkZipcode();
				this.calculateUpsell();
				// console.log('Vue mounted');
			},
		});
	}
}

window.app.add('cart', new cartHelper());
