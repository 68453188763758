window.$ = require('jquery');
window.popper = require('popper.js');
require('bootstrap');
window._ = require('lodash');
window.axios = require('axios');

/**
 * @Global - Vue inclusion
 *
 */
// import 'es6-promise/auto';

import 'custom-event-polyfill';
import 'url-search-params-polyfill';
import Vue from 'vue';
window.Vue = Vue;

/**---- @Vue Configuration ----*/

/**
 * @Vue - Extensions
 *
 */
Vue.prototype.$ = window.$ || window.jQuery;
Vue.prototype._ = window._;
Vue.prototype.axios = window.axios;
/**
 * @Vue - Config
 *
 */
Vue.config.devtools = true;
Vue.config.productionTip = false;
/**
 * @Vue - Filters
 */
Vue.filter('currency', value => '$' + parseFloat(value).toFixed(2));
Vue.filter('json', value => JSON.stringify(value));
/**
 * Vue Components
 *
 * @Devnote we can probably find a more elegant way to autoload
 * the components directory and automate the vue component
 * registration line.
 *
 * @Devnote since Vue 2, the vue-loader exports the component as the
 * `default` key, which we need to explicitly use for registration.
 *
 */
Vue.component(
	'shop-wholesale-component',
	require('./components/shop-wholesale.component.vue').default
);
Vue.component(
	'checkout-wholesale-component',
	require('./components/checkout-wholesale.component.vue').default
);
Vue.component(
	'cart-wholesale-component',
	require('./components/cart-wholesale.component.vue').default
);
Vue.component(
	'search-component',
	require('./components/search.component.vue').default
);
Vue.component(
	'filters-component',
	require('./components/filters.component.vue').default
);
Vue.component(
	'order-table-component',
	require('./components/order-table.component.vue').default
);
Vue.component(
	'order-table-group-component',
	require('./components/order-table-group.component.vue').default
);
Vue.component(
	'order-table-item-component',
	require('./components/order-table-item.component.vue').default
);
Vue.component(
	'order-table-cart-component',
	require('./components/order-table-cart.component.vue').default
);
Vue.component(
	'order-table-cart-item-component',
	require('./components/order-table-cart-item.component.vue').default
);
Vue.component(
	'product-title-component',
	require('./components/product-title.component.vue').default
);
Vue.component(
	'product-quantity-component',
	require('./components/product-quantity.component.vue').default
);
Vue.component(
	'modal-plant-detail-component',
	require('./components/modal-plant-detail.component.vue').default
);
Vue.component(
	'promo-codes-component',
	require('./components/promo-codes.component.vue').default
);
Vue.component(
	'gift-cards-component',
	require('./components/gift-cards.component.vue').default
);

import { WholesaleApp } from './containers/wholesale.app';

/**
 * @devnote we should only call this in context of the wholesale
 * application, right?
 */
new WholesaleApp();
