<template>
    <aside>
        <div class="modal modal-plant_detail fade"
            :class="{ 'show d-block': show }" 
            tabindex="-1" 
            role="dialog"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div v-if="plantDetails" class="modal-content p-0">
                    <div class="modal-header p-0">
                        <a href="#" 
                            class="modal-dismiss text-dark" 
                            @click.stop.prevent="dismiss()"
                        >
                            <span class="fa fa-times"></span>
                        </a>
                        <div class="row mx-0">
                            <div class="col-12 col-md-6 plant-image d-flex align-items-center justify-content-center p-0" v-bind:style="plantImageStyle">
                                <img class="" :src="plantImageUrl" :alt="plantDetails.BotanicalName + ' Image'"/>
                                <p v-if="plantDetails.PictureCreditText" class="picture-credit">{{ plantDetails.PictureCreditText }}</p>
                                <span v-if="!plantImageUrl">No Image</span>
                            </div>
                            <div class="col-12 col-md-6 plant-meta pt-2">
                                <p class="h1 title">{{ plantDetails.BotanicalName }}</p>
                                <p class="my-2">{{ plantDetails.Description }}</p>
                                <button class="btn btn-success w-100 add-to-favorites px-3" 
                                    @click.stop.prevent="toggleFavorite()"
                                >
                                    <span class="fa mr-1"
                                    :class="{ 'fa-heart-o': !favorited, 'fa-heart': favorited }">
                                    </span> Add to Favorites
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body p-1 p-md-2">
                        <!-- {{ plantDetails|json }} -->
                        <ul class="list-group">
                            <li class="list-group-item">
                                <p class="h2 my-1">Plant Details</p>
                            </li>
                            <!-- Plant Meta -->
                            <li v-for="key of plantMetaKeys"
                                :key="key" 
                                class="list-group-item plant-meta"
                            >
                                <div class="d-flex justify-content-between plant-meta-item">
                                    <span class="mr-1">{{ plantMetaMap[key] }}</span>
                                    <span class="text-right">{{ plantDetails[key] || 'N/A' }}</span>
                                </div>
                            </li>
                            <!-- Plant Attributes -->
                            <li class="list-group-item plant-attributes">
                                <span class="d-block">Attributes</span>
                                <span class="plant-attributes-text">{{ plantAttributesText }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="modal-footer justify-content-center">
                        <a href="#"
                            class="text-muted" 
                            @click.stop.prevent="dismiss()"
                        >
                            Close
                        </a>
                    </div>
                </div>
                <div v-if="!plantDetails"
                    class="p-2 text-center text-muted"
                >
                    <p class="mb-0">Loading plant details...</p>
                </div>
            </div>
        </div>
        <div class="modal-backdrop fade" :class="{ 'show': show, 'd-none': !show }" @click="dismiss()"></div>
    </aside>
</template>
<style lang="scss" scoped>
a.modal-dismiss {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 2;
    @media screen and (max-width: 600px){
        color: white !important;
        padding: 0.5rem;
        background: rgba(0, 0, 0, 0.5);
        font-size: 24px;
        right: 0;
        top: 0;
        padding: 1rem;
    }
}
.h1.title {
    font-size: 42px;
    font-weight: bold;
}
.h2 {
    font-size: 24px;
    font-weight: bold;
}
.plant-image {
    img {
        width: 100%;
        height: auto;
    }
}
.list-group {
    box-shadow: 0px 3px 3px rgba(#000, .2);
}
.modal.modal-plant_detail {
    overflow: scroll;
    .modal-content {
        font-size: 16px;
        font-family: 'Source Sans Pro', sans-serif !important;

        .modal-header {
            border-bottom: none;
            .row {
                .plant-image {
                    align-self: stretch;
                    background-size: cover;
                    background-position: center center;

                        .picture-credit {
                            position: absolute;
                            bottom:0;
                            right:1rem;
                            color: #FFF;
                            max-width: 200px;
                            text-shadow: 1px 1px #111111;
                        }
                }
            }
        }
        .modal-footer {
            border-top: none;
        }

        ul.list-group{
            border: 1px solid rgba(0, 0, 0, 0.125);


            li.list-group-item{
                border: none;
                // padding: 0;
                padding: .5rem 4rem;
                @media screen and (max-width: 600px){
                    padding: 0.5rem 0.5rem;
                }


                div.plant-meta-item{
                    padding-bottom: 1rem;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                }
            }
        }
    }
    
}
</style>
<script>
import { STORE_ACTIONS } from '../store';
import { WholesaleApiService } from '../services/wholesale-api.service';
Vue.prototype.api = WholesaleApiService;
export default {
    data(){
        return {
            favorited: false,
            plantDetails: null,
            plantMetaMap: {
                "CommonName": 'Common Name',
                "PlantGroup": 'Category',

                "LeafColorGrowing": 'Leaf Color',
                "LeafFallColorTxt": 'Fall Leaf Color',

                "BloomColorTxt": 'Flower Color',
                "BloomTimeTxt": 'Bloom Time',

                "ExposureTxt": 'Sun Exposure',

                "GrowthRate": 'Growth Rate',

                "FormMature": 'Mature Shape',
                "HeightTxt": 'Mature Height',
                "WidthTxt": 'Mature Width',

                // "BloomColor1": null,
                // "BloomColor2": null,
                // "BloomColor3": null,
                // "BloomColorTxt": null,
                // "BloomFebruary": 0,
                // "BloomMarch": 0,
                // "BloomApril": 0,
                // "BloomMay": 0,
                // "BloomJune": 0,
                // "BloomJuly": 0,
                // "BloomAugust": 0,
                // "BloomSeptember": 0,
                // "BloomOctober": 0,
                // "LeafFallColor1": null,
                // "LeafFallColor2": null,
                // "LeafFallColor3": null,
                // "LeafFallColorTxt": null,
                // "ExposureLikesSun": 1,
                // "ExposureLikesPartShade": 0,
                // "ExposureLikesShade": 0,
                // "ToleratesWet": 0,
                // "ToleratesDrought": 0,
                // "AttractsHummingbirds": 0,
                // "AttractsButterflies": 0,
                // "DeerResistant": 1,
                // "AttractsBirdsWildlife": 0,
                // "Native": 0,
                // "SortOrder": 3707
            },
            plantAttributesMap: {
                "ToleratesWet": 'Tolerates Wet',
                "ToleratesDrought": 'Tolerates Drought',
                "AttractsHummingbirds": 'Attracts Hummingbirds',
                "AttractsButterflies": 'Attracts Butteflies',
                "DeerResistant": 'Deer Resistant',
                "AttractsBirdsWildlife": ' Attracts Birds and Wildlife',
            },
        };
    },
    computed: {
        plantDetailId() {
            return this.$store.state.plantDetailId;
        },
        plantImageUrl() {
            return this.plantDetails.PictureFileName ? `/assets/plants/${
                encodeURIComponent(this.plantDetails.PictureFileName)
                .replace(/'/g, "%27")
            }`: null;
        },
        plantImageStyle() {
            return this.plantImageUrl ? {
                backgroundImage: `url(${this.plantImageUrl})`,
            } : {
                backgroundColor: '#e1e1e1',
            };
        },
        show() {
            return this.$store.getters.showPlantDetail;
        },
        plantMetaKeys() { 
            return Object.keys(this.plantMetaMap); 
        },
        plantAttributesKeys() {
            return Object.keys(this.plantAttributesMap);
        },
        plantAttributesText() {
            const displayAttributes = this.plantAttributesKeys.reduce((hasAttrs, attr) => {
                if (this.plantDetails[attr]) {
                    hasAttrs.push(this.plantAttributesMap[attr]);
                }
                return hasAttrs;
            }, []);
            if (!displayAttributes.length) {
                return 'N/A';
            }
            return displayAttributes.join(', ');
        }
    },
    methods: {
        addToFavorites() {},
        dismiss() {
            this.$store
                .dispatch(STORE_ACTIONS.HIDE_PLANT_DETAIL_MODAL);
        },
        toggleFavorite() {
            this.favorited = !this.favorited;
            if (this.favorited) {
                // add
                this.api
                    .addFavoriteForAuthenticatedUser(this.plantDetailId)
                    .then(
                        (favorite) =>
                        this.$store
                            .dispatch(STORE_ACTIONS.FAVORITES_ADD, favorite)
                    );
            } else {
                // delete
                this.api
                    .removeFavoriteForAuthenticatedUser(this.plantDetailId)
                    .then(
                        (removed) =>
                        this.$store
                            .dispatch(STORE_ACTIONS.FAVORITES_REMOVE, this.plantDetailId)
                    );
            }
        }
    },
    mounted() {
        this.$store
            .watch(
                (state, getters) => state.plantDetailId,
                (plantDetailId) => {
                    if (plantDetailId) {
                        // Set Favorite State on Load
                        this.favorited = this.$store.getters.favoriteItemsIds.indexOf(plantDetailId) > -1;
                        this.api
                            .getPlantDetails(plantDetailId)
                            .then(
                                (details) =>
                                this.plantDetails = details
                            );
                    } else {
                        this.plantDetails = null;
                    }
                }
            );
    }
}

const exampleDetails = {
    "PlantClassID": "00530",
    "PlantGroup": "Shrub",
    "BotanicalName": "Abies koreana 'Cis'",
    "CommonName": "Cis Dwarf Korean Fir",
    "HeightTxt": "10in to 12in",
    "WidthTxt": "12in to 18in",
    "FormMature": "Mounding",
    "BloomColor1": null,
    "BloomColor2": null,
    "GrowthRate": "Slow",
    "BloomColor3": null,
    "BloomColorTxt": null,
    "BloomTimeTxt": "",
    "BloomFebruary": 0,
    "BloomMarch": 0,
    "BloomApril": 0,
    "BloomMay": 0,
    "BloomJune": 0,
    "BloomJuly": 0,
    "BloomAugust": 0,
    "BloomSeptember": 0,
    "BloomOctober": 0,
    "LeafColorGrowing": "Green",
    "LeafFallColor1": null,
    "LeafFallColor2": null,
    "LeafFallColor3": null,
    "LeafFallColorTxt": null,
    "ExposureLikesSun": 1,
    "ExposureLikesPartShade": 0,
    "ExposureLikesShade": 0,
    "ExposureTxt": "Likes Sun",
    "ToleratesWet": 0,
    "ToleratesDrought": 0,
    "AttractsHummingbirds": 0,
    "AttractsButterflies": 0,
    "DeerResistant": 1,
    "AttractsBirdsWildlife": 0,
    "Native": 0,
    "Description": "A very slow growing evergreen with a globe shape and dark green needles that have a silvery cast. Its neat, tidy appearance makes it well suited to formal landscapes and or use as an accent shrub.",
    "PictureFileName": "AbieskoreanaCis_9369 iseli tag.jpg",
    "PictureCreditText": "Courtesy of Iseli Nursery",
    "LastEditDate": "2018-01-25 14:49:25",
    "Active": 1,
    "AvailBannerText": null,
    "AvailBannerColor": null,
    "SortOrder": 3707
};
</script>